<template>
  <div class="services">
    <div class="page-header">
      <h1>{{ $t('Service Management') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Services') }}</h2>
        <router-link :to="{ name: 'CreateService' }">
          <el-button class="action-button" type="primary">{{ $t('Add Service') }}</el-button>
        </router-link>
      </div>
      <ItemFilter :placeholder="$t('Search by service number, service name')" default="serviceNum" @search="searchItems">
        <el-option :label="$t('Service No. Search')" value="serviceNum"></el-option>
        <el-option :label="$t('Service Name Search')" value="serviceName"></el-option>
      </ItemFilter>
      <div class="service-list">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Service ID') }}</th>
              <th scope="col">{{ $t('Service Name') }}</th>
              <th scope="col">{{ $t('Service Price') }}</th>
              <th scope="col">{{ $t('Created Date') }}</th>
              <th scope="col">{{ $t('Actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="service in services">
              <td>{{ service.reference_id }}</td>
              <td v-if="langcode === 'en'">{{ service.service_name_en }}</td>
              <td v-else="langcode === 'zh'">{{ service.service_name_zh }}</td>
              <td>HK${{ service.service_price }}</td>
              <td>{{ formatDateTime(service.created_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Service', params: {id: service.id}}">
                    {{ $t('Edit') }}
                  </router-link>
                  <a class="action-link danger" href="#" @click="deleteService(service.id)">{{ $t('Delete') }}</a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Service from '@/lib/service';
import ItemFilter from '@/components/Filter.vue';

export default {
  name: 'Services',
  components: {
    ItemFilter,
  },
  mounted(){
    this.loadServices();
  },
  data(){
    return {
      services: [],
      orignalServices: [],
    }
  },
  methods:{
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'serviceName':
        if(this.langcode === 'zh'){
          searchField['service_name_zh'] = form.filterValue;
        }else{
          searchField['service_name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalServices);
        this.searchServices = newItems;
        this.services = newItems;
        break;

        case 'serviceNum':
        searchField['reference_id'] = form.filterValue;
        newItems = Common.filterItems(searchField, this.orignalServices);
        this.searchServices = newItems;
        this.services = newItems;
        break;
      }
    },
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    async loadServices(){
      try{
        const loginInfo = Common.getLoginInfo();
        const services = await Service.loadAllServices(this.apiUrl, loginInfo);
        this.orignalServices = services;
        this.services = services;
      }catch(err){
        console.log(err);
      }
    },
    async deleteService(serviceId){
      try{
        const loginInfo = Common.getLoginInfo();
        await Service.deleteService(this.apiUrl, serviceId, loginInfo);
        const newServices = this.services.filter(function(item) {
          return item.id !== serviceId;
        });
        this.orignalServices = newServices;
        this.services = newServices;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
